<template>
  <div class="boxsu">
    <el-row class="dash-board">
      <!-- <el-button type="primary" v-permission="['Summary:selectTwo']" @click="getTwo" class="ww">班主任查询</el-button> -->
      <el-button type="primary" @click="getone" class="ww">查询</el-button>
      <el-button type="warning" @click="reset" class="wwww">重置</el-button>
      <el-button type="primary" v-permission="['Summary:add']" @click="add" class="tianxie">填写总结</el-button>
      <span style="font-size: 12px" class="qwe" >班级:</span>
      <el-select style="width: 190px" class="wsd" placeholder="请选择班级" v-model="className" filterable clearable>
      <el-option v-for="(item,index) in classData"
                  :key="index"
                  :label="item.className"
                  :value="item.classId"/>
      </el-select>
      <div v-permission="['Summary:teacherSelect']">
        <span style="font-size: 12px" class="ewq">班主任:</span>
        <el-input placeholder="输入姓名" v-model="teacherName" style="width: 150px" class="dsw"></el-input>
      </div>
    </el-row>
    <el-row class="bg">
    <el-table
      :data="tableData"
      style="width: 100%;">
      <el-table-column
          label="姓名"
          align="center"
          width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.teacher }}</span>
          </template>
      </el-table-column>
      <el-table-column
        label="班级"
        align="center"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.className }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="提交时间"
        align="center"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.time }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="联系电话"
        align="center"
        width="200">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" v-permission="['Summary:see']" @click="see(scope.row.classId)">查 看</el-button>
          <el-button size="mini" type="primary" v-permission="['Summary:edit']" @click="edit(scope.row.classId)">修 改</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <el-dialog
        title="填写总结"
        :visible.sync="dialogVisible">
        <el-form
          :model="ruleForm2"
          status-icon
          ref="ruleForm2"
          :rules="rules2"
          class="demo-ruleForm"
           label-width="80px"
        >
          <el-form-item prop="className" label="班级">
            <el-select style="width: 100%;" v-model="ruleForm2.className" placeholder="请选择班级名称">
               <el-option v-for="(i,k) in classData"
                            :key="k"
                            :label="i.className"
                            :value="i.classId"/>
                </el-select>
          </el-form-item>
          <el-form-item prop="teacherZj" label="总结">
            <el-input type="textarea" :rows="10" maxlength="500" show-word-limit placeholder="请填写班级总结" v-model="ruleForm2.teacherZj"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
          </span>
    </el-dialog>
    <el-dialog
        title="修改总结"
        :visible.sync="dialogVisibleThree">
        <el-form
          :model="ruleForm"
          status-icon
          ref="ruleForm"
          :rules="rules2"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item prop="className" label="班级">
            <el-input placeholder="请填写班级" readonly v-model="ruleForm.className"></el-input>
          </el-form-item>
          <el-form-item prop="teacherZj" label="总结">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请填写班级总结"
              v-model="ruleForm.teacherZj"
              maxlength="500"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleThree = false">取 消</el-button>
            <el-button type="primary" @click="editForm('ruleForm')">确 定</el-button>
          </span>
    </el-dialog>
   <el-dialog
        title="查看班级总结"
        :visible.sync="dialogVisibleTwo">
        {{seeData.teacherZj}}
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisibleTwo = false">关 闭</el-button>
          </span>
    </el-dialog>
    <el-row style="margin-top:20px">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total,sizes,  prev, pager, next, jumper"
      :total="total"
      class="pag">
    </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { summaryList, getclassName, insTeacherZj, seeclassData } from '@/api/training.js'
  import { getAllClassByMoHu } from '@/api/user.js'
  export default {
    name: 'Summary',
    data () {
      return {
        classData: [], // 存储班级列表信息
        className: '',
        teacherName: '',
        tableData: [],
        dialogVisible: false,
        ruleForm2: {
          className: '',
          teacherZj: ''
        },
        rules2: {
          className: [{ required: true, message: '请选择班级', trigger: 'blur' }],
          teacherZj: [{ required: true, message: '请填写班级总结', trigger: 'blur' }]
        },
        classList: [],
        dialogVisibleTwo: false,
        seeData: {},
        dialogVisibleThree: false,
        classId: '',
        ruleForm: {
          className: '',
          teacherZj: ''
        },
        query: {},
        total: 0,
        pageNum: 1,
        pageSize: 10
      }
    },
    mounted () {
      // 钩子函数
      this.getone()
      this.findClass()
    },
    methods: {
       // 获取班级列表
      findClass() {
        getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
          console.log(this.classData)
        })
      },
      // 管理员初始化列表
      getone() {
        //  const adminPermsString = getCookie('perms')
        //  const adminPerms = adminPermsString.split(',')
        //  adminPerms.forEach(adminData => {
        //       if (adminData === 'Summary:selectTwo') {
        //         this.query = {
        //           classId: this.className,
        //           teacherName: this.teacherName
        //         }
        //       } else if (adminData === 'Summary:select') {
        //         this.query = {
        //           // userName: this.$store.getters.username,
        //           classId: this.className,
        //           teacherName: this.teacherName
        //         }
        //       }
        //   })
        this.query = {
          classId: this.className,
          teacherName: this.teacherName
        }
        summaryList(this.query).then(resp => {
            if (resp.data.code === '200') {
              if (resp.data.data.rows) {
              this.tableData = resp.data.data.rows
              this.total = resp.data.data.total // 查询总条数
              }
            }
        })
      },
      reset() {
        this.className = ''
        this.teacherName = ''
      },
      add() {
        this.dialogVisible = true
        this.GetAllById()
      },
      // 班级总结提交
      submitForm (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const query = {
              classId: this.ruleForm2.className,
              teacherZj: this.ruleForm2.teacherZj
            }
            insTeacherZj(query).then(resp => {
              this[ruleForm2] = {}
                if (resp.data.code === '200') {
                  this.$notify({
                    title: '提交成功',
                    type: 'success'
                  })
                  this.getone()
                  this.dialogVisible = false
                }
            })
          }
        })
      },
      // 获取班级列表,以前的
     GetAllById () {
        getclassName(this.$store.getters.username).then(resp => {
          console.log(resp.data)
          if (resp.data.code === '200') {
              this.classList = resp.data.data
            }
        })
      },
      // 查看
      see(classId) {
        this.dialogVisibleTwo = true
        this.classId = classId
        seeclassData(this.classId).then(resp => {
          if (resp.data.code === '200') {
             this.seeData = resp.data.data
          }
        })
      },
      // 修改
      edit(classId) {
        this.dialogVisibleThree = true
        this.classId = classId
        seeclassData(this.classId).then(resp => {
          if (resp.data.code === '200') {
             this.ruleForm = resp.data.data
          }
        })
      },
      editForm(ruleForm) {
        this.$refs[ruleForm].validate(valid => {
          if (valid) {
            const query = {
              classId: this.classId,
              teacherZj: this.ruleForm.teacherZj
            }
            insTeacherZj(query).then(resp => {
              this[ruleForm] = {}
                if (resp.data.code === '200') {
                  this.$notify({
                    title: '提交成功',
                    type: 'success'
                  })
                  this.getone()
                  this.dialogVisibleThree = false
                }
            })
          }
        })
      },
      // 分页
      handleSizeChange(e) {
        this.pageSize = e
        this.getone()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getone()
      }
    }
  }
</script>

<style scoped>
  .boxsu{margin-top: -10px}
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    position: relative;
  }
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .b{
    position: absolute;
    top: 5px;
    left: 540px;
  }
  .c{
    position: absolute;
    left: 70%;
    top: 5px;
  }
  .d{
    position: absolute;
    left: 90%;
    top: 5px;
  }
  .ccccc{
    position: absolute;
    left: 50%;
    top: 15px;
  }
  .wwww{
    position: absolute;
    left: 528px;
    top: 15px;
  }
  .ww{
    position: absolute;
    left: 472px;
    top: 15px;
  }
  .qwe{position: absolute; top: 20px;left: 10px}
  .wsd{position: absolute; left: 45px; top: 15px}
  .ewq{position: absolute; left: 260px;top: 20px}
  .dsw{position: absolute; left: 310px; top: 15px }
  .tianxie{position: absolute;left: 594px; top: 15px}
</style>
